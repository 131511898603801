.login-container {
    width: 100%;
    max-width: 420px;
    margin: auto;
    padding-top: 5%;

}

/*.logo {
    height: 140px;
}

.logo img {
    height: 100%;
    width: 100%;
}

[data-title] {
    position: relative;
    cursor: pointer;
}

[data-title]:hover::before {
    content: attr(data-title);
    position: absolute;
    z-index: 4;
    bottom: -58px;
    display: inline-block;
    padding: 5px 6px;
    border-radius: 2px;
    background: #00425f;
    color: #fff;
    font-size: 10px;
    font-family: sans-serif;
    left: 40px;
    max-width: 900px;
    overflow-wrap: break-word;
    word-break: pre-wrap;
}

*/

.login-wrap {
    /* box-shadow: 1px 1px 9px 0 #6e6a6a; */
    -webkit-box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
    margin: 0 auto;
    margin-top: -20px;
    padding: 40px 40px 45px 40px;
}

.login-header {
    line-height: 19px;
    font-size: 13px;
    color: #00425f;
    letter-spacing: .2px;
    text-align: center;
}

.login-form {
    margin-top: 22px;
}

.email,
.password {
    margin-bottom: 20px;
}

.forgot-password {
    color: #00425f;
    cursor: pointer;
    font-size: 12px;
    margin-top: 16px;
    text-align: right;
}


.signUp {
    line-height: 19px;
    font-size: 13px;
    letter-spacing: .2px;
    text-align: center;
    margin-top: 18px;
}

.signUp>span {
    color: rgba(0, 0, 0, .7);
}

.signUp a {
    color: #00425f;
    cursor: pointer;
}


/* @media screen and (max) */

@media screen and (max-width: 400px) {
    .container {
        max-width: 300px;
    }

    .login-wrap {
        padding: 40px 20px 45px 20px;
    }
}