.cont {
  height: 100vh;
  overflow: hidden scroll;
}
/*# sourceMappingURL=signup.css.map */
.email-verify {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.verify-header {
  height: 120px;
  width: 100%;
  background: #00425f;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.verify-button{
  padding: auto 0;
  margin: 0;
  width: 168px;
}
.verify-footer{
position: fixed;
/* bottom: 0; */
top: 80%;
text-align: center;
}

.verify-footer a{
  color: #479FC8;
  font-size: 16px;
  line-height: 24px;
  font-weight: 800;
}
.verify-footer p{
  color: #111827;
  font-size: 14px;
  line-height: 20px;
  /* padding: 0;
  margin: 0; */
}
