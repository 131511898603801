.absolute-zero {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.absolute-middle {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.pos-f {
  position: fixed;
}

.pos-f.fixed-to-top {
  top: 0;
  right: 0;
  left: 0;
}

.pos-a {
  position: absolute;
}

.pos-r {
  position: relative;
}

.extra {
  color: #fff;
  font-size: 0.815rem;
  font-weight: 100;
}

@media screen and (max-width: 324px) {
  .extra {
    font-size: 0.600rem;
  }
}

@media screen and (max-width: 364px) {
  .extra {
    font-size: 0.630rem;
  }
}

@media screen and (max-width: 384px) {
  .extra {
    font-size: 0.730rem;
  }
}

.hid-btn {
  display: none;
}

.hid-btn2 {
  display: none;
  margin-left: 20px;
}

.btnf {
  margin-left: 80px;
}

@media screen and (max-width: 322px) {
  .btnf {
    margin-left: 0;
  }
}

@media screen and (max-width: 384px) {
  .btnf {
    margin-left: 40px;
  }
}

@media screen and (max-width: 414px) {
  .btnf {
    margin-left: 0;
  }
}

@media screen and (device-width: 1024px) and (max-height: 829px) {
  .btnf {
    width: 95%;
    margin-left: 10px;
  }
}

.csr {
  cursor: pointer;
}

.d-blk {
  display: block;
}

.d-ibl {
  display: inline-block;
}

.d-flx {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.d-flx-c {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.d-grd {
  display: -ms-grid;
  display: grid;
}

.flx-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flx-1-5 {
  -webkit-box-flex: 1.5;
  -ms-flex: 1.5;
  flex: 1.5;
}

.flx-1-8 {
  -webkit-box-flex: 1.8;
  -ms-flex: 1.8;
  flex: 1.8;
}

.flx-2 {
  -webkit-box-flex: 2;
  -ms-flex: 2;
  flex: 2;
}

.flx-2-5 {
  -webkit-box-flex: 2.5;
  -ms-flex: 2.5;
  flex: 2.5;
}

.flx-3 {
  -webkit-box-flex: 3;
  -ms-flex: 3;
  flex: 3;
}

.flx-7 {
  -webkit-box-flex: 7;
  -ms-flex: 7;
  flex: 7;
}

.flx-7-5 {
  -webkit-box-flex: 7.5;
  -ms-flex: 7.5;
  flex: 7.5;
}

.flx-dir-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.d-ifx {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.al-i-fs {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.al-i-c {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.j-c-c {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.j-c-sb {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.j-c-fe {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.space-between-groups {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

html {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

body {
  font-family: 'Source Sans Pro', sans-serif !important;
}

html,
body,
ul,
li,
form,
fieldset,
legend {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

fieldset,
img {
  border: 0;
}

li {
  list-style: none;
}

.wrapper {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

input {
  outline: none;
  border-radius: 2px;
  border: 0;
}

button {
  outline: none;
}

a {
  text-decoration: none;
  color: white;
}

.loading-container, .loading-modal-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100vh;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

button.dot:disabled {
  color: white;
  cursor: not-allowed;
  font-weight: 500;
}

@-webkit-keyframes blink {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}

@keyframes blink {
  0% {
    opacity: .2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: .2;
  }
}

.form-field__input label[for='quantity'] {
  margin-left: 8px;
  padding-top: 4px;
  color: #00425f;
}

.form-field__input input {
  margin-left: 8px;
}

.form-field__input label[data-shrink='false'] {
  -webkit-transform: translate(0, 12px) scale(1);
  transform: translate(0, 12px) scale(1);
  -webkit-transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.form-field__input div::after {
  border-color: #00425f;
}

.form-field {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.form-field .error {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.form-field .error label {
  color: #ee312a !important;
}

.form-field div::after {
  border-color: #00425f;
}

.ant-form-item > .ant-form-item-control-wrapper > .ant-form-item-control > .ant-form-item-children > .form-field__control {
  -webkit-box-shadow: #e8f0fd 0 0 0 1000px inset;
  box-shadow: #e8f0fd 0 0 0 1000px inset;
}

.ant-form-item > .ant-form-item-control-wrapper > .ant-form-item-control > .ant-form-item-children > .form-field__control .form-field__label {
  height: 13px;
  margin: 0;
  padding-top: 8px;
}

.ant-form-item > .ant-form-item-control-wrapper > .ant-form-item-control > .ant-form-item-children > .form-field__control .form-field__input {
  margin: 0;
  padding-bottom: 2px;
}

.ant-form-item > .ant-form-item-control-wrapper > .ant-form-item-control.has-error > .ant-form-item-children.form-field--is-active > .form-field__control {
  background: rgba(238, 49, 42, 0.2);
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #ee312a;
}

/* .isw-nav--header {
  min-height: 61px;
  padding: .7rem 1rem;
}

.isw-nav--header .isw-nav--pad {
  padding: 0;
}

.isw-nav--header svg {
  margin-left: -20px;
} */

.dropdown-item:hover {
  background: #e6f7ff;
  cursor: pointer;
}

.dropdown.dropdown-toggle.kam {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.dropdown.dropdown-toggle.kam:hover .dropdown-menu.kam {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 1px 1px 10px rgba(153, 153, 153, 0.3);
  box-shadow: 1px 1px 10px rgba(153, 153, 153, 0.3);
}

.dropdown-menu-kam,
.dropdown-menu-kam2 {
  -webkit-box-shadow: 5px 5px 20px #e8e8e8;
  box-shadow: 5px 5px 20px #e8e8e8;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 800;
  width: 100px;
  max-width: 100px;
  top: 24px;
  border-radius: 4px;
  border: 1px solid #e8e8e8;
  position: absolute;
  padding: 4px 0;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.dropdown-menu-kam .dropdown-item,
.dropdown-menu-kam2 .dropdown-item {
  padding-left: 0.8rem;
  text-align: left;
}

.dropdown-menu-kam {
  right: -80px;
}

.dropdown-menu-kam2 {
  min-width: 195px;
  right: 24px;
}

.dropdown-menu-kam2 .reset-filters {
  padding: 0 0 0 1rem;
  cursor: pointer;
}

.dropdown-menu-kam2 .reset-filters:hover {
  color: #1890ff;
}

.drawer-btn-cont {
  z-index: 20;
}

.ant-select-selection .ant-select-selection--single {
  height: 40px;
}

.ant-select-selection .ant-select-selection--single .ant-select-selection__rendered {
  height: inherit;
}

.tab-content {
  height: 80vh;
}

.ant-notification.ant-notification-topRight .ant-notification-notice {
  margin: 0;
}

.ant-notification.ant-notification-topRight .ant-notification-notice.error {
  background: #ee312a;
}

.ant-notification.ant-notification-topRight .ant-notification-notice.success {
  background: #2DB004;
}

.ant-notification.ant-notification-topRight .ant-notification-notice .ant-notification-notice-icon {
  position: absolute;
  margin-left: 4px;
  font-size: 24px;
  line-height: 16px;
}

.ant-notification.ant-notification-topRight .ant-notification-notice .ant-notification-notice-message {
  color: #fff;
}

.ant-notification.ant-notification-topRight .ant-notification-notice svg {
  fill: #fff;
}

.ant-table-fixed-right {
  overflow: visible;
}

.smartfuel-logo {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.smartfuel-logo .cls-1, .smartfuel-logo .cls-2, .smartfuel-logo .cls-5 {
  fill: #03435f;
}

.smartfuel-logo .cls-1, .smartfuel-logo .cls-3 {
  clip-rule: evenodd;
}

.smartfuel-logo .cls-3, .smartfuel-logo .cls-4 {
  fill: none;
}

.smartfuel-logo .cls-5 {
  fill-rule: evenodd;
}

.smartfuel-logo .cls-6 {
  -webkit-clip-path: url(#clip-path);
          clip-path: url(#clip-path);
}

.smartfuel-logo .cls-7 {
  -webkit-clip-path: url(#clip-path-2);
          clip-path: url(#clip-path-2);
}

.smartfuel-logo .cls-8 {
  -webkit-clip-path: url(#clip-path-3);
          clip-path: url(#clip-path-3);
}

.smartfuel-logo .cls-9 {
  -webkit-clip-path: url(#clip-path-5);
          clip-path: url(#clip-path-5);
}

.smartfuel-logo .cls-10 {
  -webkit-clip-path: url(#clip-path-7);
          clip-path: url(#clip-path-7);
}

.smartfuel-logo .cls-11 {
  -webkit-clip-path: url(#clip-path-9);
          clip-path: url(#clip-path-9);
}

.smartfuel-logo .cls-12 {
  -webkit-clip-path: url(#clip-path-11);
          clip-path: url(#clip-path-11);
}

.smartfuel-logo .cls-13 {
  -webkit-clip-path: url(#clip-path-13);
          clip-path: url(#clip-path-13);
}

.smartfuel-logo .cls-14 {
  -webkit-clip-path: url(#clip-path-15);
          clip-path: url(#clip-path-15);
}

.smartfuel-logo .cls-15 {
  -webkit-clip-path: url(#clip-path-17);
          clip-path: url(#clip-path-17);
}

.smartfuel-logo .cls-16 {
  -webkit-clip-path: url(#clip-path-19);
          clip-path: url(#clip-path-19);
}

.smartfuel-logo .cls-17 {
  -webkit-clip-path: url(#clip-path-21);
          clip-path: url(#clip-path-21);
}

.smartfuel-logo .cls-18 {
  -webkit-clip-path: url(#clip-path-23);
          clip-path: url(#clip-path-23);
}

.smartfuel-logo .cls-19 {
  -webkit-clip-path: url(#clip-path-24);
          clip-path: url(#clip-path-24);
}

.smartfuel-logo .cls-20 {
  fill: #ee342b;
}

.smartfuel-logo .cls-21 {
  -webkit-clip-path: url(#clip-path-25);
          clip-path: url(#clip-path-25);
}

.white .st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #fff;
}

.white .st1 {
  -webkit-clip-path: url(#SVGID_2_);
          clip-path: url(#SVGID_2_);
}

.white .st2 {
  -webkit-clip-path: url(#SVGID_4_);
          clip-path: url(#SVGID_4_);
  fill: #fff;
}

.white .st3 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #EE342B;
}

.white .st4 {
  fill: #fff;
}

.blue .st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #03435f;
}

.blue .st1 {
  -webkit-clip-path: url(#SVGID_2_);
          clip-path: url(#SVGID_2_);
}

.blue .st2 {
  -webkit-clip-path: url(#SVGID_4_);
          clip-path: url(#SVGID_4_);
  fill: #03435f;
}

.blue .st3 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #EE342B;
}

.blue .st4 {
  fill: #03435f;
}

html {
  height: 100%;
  width: 100%;
  margin: 0 auto !important;
}

.isw-error {
  position: relative;
  min-height: 100vh;
}

.isw-error .isw-image {
  width: calc(100% - 2rem);
}

.isw-error .isw-error-wrapper {
  text-align: center;
  margin: 0 auto;
}

.isw-error .err-text-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 16px;
}

.isw-mainLayout1 {
  overflow: hidden scroll;
  padding-left: 250px;
}

@media (min-width: 320px) and (max-width: 991px) {
  .isw-mainLayout1 {
    padding: 0;
  }
}

.navbar-ag {
    /* width: 100%; */
    /* height: 4.625rem; */
    z-index: 700 !important;
    left: 0px;
    top: 0px;
    position: fixed;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}

.ant-notification {
    z-index: 9999 !important;
}

@media (min-width: 320px) and (max-width: 991px) {
  .isw-nav--header svg {
    margin-left: 1rem;
  }
}

/* .isw-nav--header .isw-nav--pad .isw-logo-menu {
  left: -20px;
  position: absolute;
} */

.toggler-cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-bottom: 1px solid #ddd;
  color: #fff;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 61px;
}

.form_field__control_landing {
  background: transparent;
  margin: 0;
}

input.k_input {
  margin: 0;
  background: transparent;
  -webkit-text-fill-color: rgba(0, 0, 0, 0.7);
  padding: 1rem 0.75rem;
  height: 54px;
  border: 1px solid rgba(232, 232, 232, 0.9);
}

input.k_input:hover, input.k_input:focus {
  border-color: rgba(0, 66, 95, 0.3);
}

input.k_input.green {
  border-color: rgba(59, 183, 94, 0.52);
}

input.k_input.red {
  border-color: #ee312a;
}

input.k_input.isw-input-error {
  border-color: #ee312a;
}

.csr {
  cursor: pointer;
}

.text-c {
  text-align: center;
}

.disabled {
  color: #e0e0e0 !important;
  cursor: not-allowed !important;
}

.bg-primary.false {
  background: #e0e0e0 !important;
  color: #fff;
}

.bg-primary.false:hover {
  background: #e0e0e0 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transform: none;
  transform: none;
  cursor: default;
}

.isw-customer {
  position: inherit;
}

.isw-customer nav {
  position: inherit;
}

.isw-customer nav .breadcrumb {
  background: #fff;
  border: 1px solid #dee2e6;
}

.isw-customer nav .breadcrumb .breadcrumb-item {
  font-size: 1em;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
}

.isw-customer nav .breadcrumb .breadcrumb-item.active {
  color: #00425f;
  font-weight: 500;
}

.isw-customer .accordion {
  position: inherit;
}

.isw-customer .accordion .card-header {
  padding: 0;
}

.isw-customer .accordion .card-header .btn-link {
  padding: 1rem;
  width: 100%;
  text-align: left;
  text-decoration: none;
  color: #00425f;
  font-weight: 500;
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes wink {
  50% {
    opacity: 0;
  }
}

@keyframes wink {
  50% {
    opacity: 0;
  }
}

.isw-customer .spinner {
  width: 7em;
  margin: 3em auto;
  position: relative;
}

.isw-customer .spinner:before {
  content: "";
  display: block;
  width: 7em;
  height: 1em;
  margin: 3em auto;
  padding: 3em 0;
  border-radius: 50%;
  border: 2px solid #2f416f;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  -webkit-animation: rotate 1s ease infinite;
  animation: rotate 1s ease infinite;
}

.isw-customer .spinner:after {
  content: "Loading";
  position: absolute;
  top: 0;
  color: #2f416f;
  font: .9em sans-serif;
  width: 9em;
  line-height: 8em;
  text-align: center;
  -webkit-animation: wink 1.4s ease infinite;
  animation: wink 1.4s ease infinite;
}

.bg-cyan {
  background-color: #00B8DE !important;
}

.bg-cyan:hover, .bg-cyan:focus {
  background-color: #008eab !important;
}

.isw-infoBox-success {
  background: rgba(45, 176, 4, 0.1);
  border: 1px solid #2DB004;
  padding: 1rem;
}

.isw-infoBox-pending {
  background: rgba(253, 126, 20, 0.1);
  border: 1px solid #fd7e14;
  padding: 1rem;
}

.isw-infoBox-failed {
  background: rgba(238, 49, 42, 0.1);
  border: 1px solid #ee312a;
  padding: 1rem;
}

svg#Capa_1 {
  height: 14px;
  width: 14px;
}

.isw-permission-name .loading-modal-container {
  height: 100%;
}

.isw-card-header .p-3 .shadow-sm {
  z-index: 100;
}

.k_input.search_field {
  border-color: #e8e8e8;
  margin: 0 0 0.5rem;
}

#location-item-span {
  padding: 0.5rem 1rem;
  margin: 4px 0;
  border: 1px solid rgba(153, 153, 153, 0.1);
  text-transform: capitalize;
  background: #e8e8e8;
}

.dropdown.dropdown-toggle.kam {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dropdown.dropdown-toggle.kam:hover {
  z-index: 100000000;
}

.dropdown.dropdown-toggle.kam:hover .dropdown-menu.kam {
  z-index: 100000000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

#selected-loc {
  text-transform: capitalize;
}

.ovh-img img {
  width: 96px;
}

.ovh-img #img-cont {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  widows: inherit;
  height: inherit;
  background: #fff;
}

.ovh-img #img-cont img {
  width: 96px;
}

.nothing-dashboard {
  color: rgba(0, 0, 0, 0.5);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0.5rem 0 0;
}

.isw-card-admin-list.kam {
  border-bottom: 1px solid #e8e8e8;
  padding: 0 0 1rem;
}

.isw-card-admin-list.kam p {
  padding: 1rem 0 0;
}

.user-perms {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 8px 0 0;
}

.isw-user-permission-action {
  position: relative;
}

.isw-logo-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.editInfo {
  padding: 0;
}

.isw-sideBar-a {
  padding: 0 0 0 30px;
}

.nav-pills > .nav-item {
  cursor: pointer;
}

.nav.nav-pills {
  width: 100%;
  display: block;
}

.nav.nav-pills > .nav-item {
  display: inline;
}

.ant-modal-title {
  color: #00425f;
}

.ant-modal-close-x {
  color: #ee312a;
}

.ant-input:hover,
.ant-input:focus,
.ant-input:active {
  border-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ant-modal-wrap,
.ant-notification,
.ant-message,
.ant-modal-mask {
  z-index: 9900;
}

.ant-table-thead > tr > th .anticon-filter {
  position: absolute;
}

.ant-table-tbody {
  border: 1px solid #e8e8ee;
}

.ant-spin-nested-loading {
  border: none;
  height: 100vh;
  overflow: hidden scroll;
  background: transparent;
}

.ant-spin-nested-loading .ant-list-item {
  width: calc(100% - 1.25rem);
}

.ant-upload.ant-upload-select.ant-upload-select-picture-card:hover {
  border-color: #00425f;
}

.ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: #ee312a;
}

.isw-btn > .material-icons {
  margin: 0 0.5rem 0 0;
}

.ant-form-explain > span {
  display: block;
}

.anticon-right > svg,
.anticon-left > svg {
  height: 1rem;
  width: 1rem;
}

.ant-message-custom-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.isw-sideBar {
  overflow: hidden;
  width: 240px;
  top: 0;
  border-right: 1px solid #e8e8e8;
}

.ant-radio-group {
  padding: 0.05rem 1rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.ant-drawer-header {
  position: fixed;
  z-index: 2;
  min-height: 65px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 0.4rem 0;
}

.ant-drawer-body {
  margin: 3rem 0;
}

.ant-drawer-body .col-lg-11 {
  border: none;
  padding: 0;
}

.ant-drawer-body .isw-card {
  border: 0;
}

.drawer-btn-cont {
  position: absolute;
  bottom: 0;
  max-height: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
}

.drawer-btn-cont span {
  font-size: 12px;
  letter-spacing: 0;
  color: #000;
}

.drawer-btn-cont .text-blue {
  margin: 0 1rem;
}

.drawer-btn-cont .text-blue span {
  color: #00425f;
}

.drawer-btn-cont .text-blue:hover {
  background: rgba(0, 66, 95, 0.1);
}

input.k_input {
  background: #e8e8e8;
}

.ant-pagination {
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
}

.ant-pagination .ant-pagination-item {
  margin: 0;
  border-top: none;
  border-bottom: none;
  border-radius: 0;
  color: #00425f;
}

.ant-pagination .ant-pagination-item:hover {
  border-color: #dee2e6;
}

.ant-pagination .ant-pagination-item-active {
  background: #00425f;
  border-color: #00425f;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.ant-pagination .ant-pagination-item-active a {
  color: #fff;
}

.ant-pagination .ant-pagination-item-active a:hover {
  color: #fff;
}

.ant-pagination .ant-pagination-prev,
.ant-pagination .ant-pagination-next {
  padding: 0 1rem;
  color: #00425f;
  background: #fff;
  margin: 0;
}

.ant-pagination .ant-pagination-prev.ant-pagination-disabled,
.ant-pagination .ant-pagination-next.ant-pagination-disabled {
  color: #6c757d;
}

.dropdown-menu-kam,
.dropdown-menu-kam2 {
  background: #fff;
}

.dropdown-item {
  color: #00425f;
}

.dropdown-item:hover {
  background: #f8f9fa;
  cursor: pointer;
}

.openModal {
  display: block;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
}

.openModal .isw-sideBarNav {
  width: 250px;
}

label[for='cssTarget'] {
  margin: 4px 0 0 8px;
}

label[for='cssTarget'][data-shrink='true'] {
  color: #00425f;
}

.form-field__input[id='cssTarget']:hover {
  border: none !important;
}

.daterangepicker .ranges li.active {
  background-color: #00425f !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: #00425f !important;
}

.isw-valign--middle {
  word-break: break-word;
  min-height: 50px;
}

.isw-valign--middle.add-border{
  text-align: center;
  border-right: 1px solid #DBDBDB;
}

.no-border{
  border-right: none !important;
}

.progress-bar {
  background: #00425f !important;
}

.delivery-card {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 12px;
}

.delivery-card .complete-btn {
  background: #02567C;
  color: #fff;
  padding: 12px;
  border-radius: 4px;
  max-width: 120px;
  width: 100%;
  text-align: center;
}

.activeCard {
  background: #02567C !important;
}

.activeCard,
.activeCard span {
  color: white;
}

.activeCard .complete-btn {
  background: #fff;
  color: #02567C;
}

.react-bootstrap-daterangepicker-container {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 0 0 auto;
}

.isw-table--body > span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.contract-detail .ant-spin-nested-loading {
  height: unset;
}

.ant-drawer-title {
  width: 100%;
  min-width: 240px;
}

.isw-permission {
  max-height: 550px;
}

.isw-permission .row {
  max-height: 520px;
}

.isw-permission .row .col-5,
.isw-permission .row .col-7,
.isw-permission .row .isw-permission-list {
  max-height: inherit;
  height: unset;
}

.isw-permission .row .isw-permission-list {
  height: 390px;
  min-height: unset !important;
}

.profile-page .isw-table--body {
  padding: 6px 12px;
}

.profile-page .ant-spin-nested-loading {
  height: 100%;
}

.otp-modal .ant-btn-primary {
  background: #00425f;
  border-color: #00425f;
}

.otp-modal input::-webkit-input-placeholder {
  color: #00425f;
}

.otp-modal input:-ms-input-placeholder {
  color: #00425f;
}

.otp-modal input::-ms-input-placeholder {
  color: #00425f;
}

.otp-modal input::placeholder {
  color: #00425f;
}

.delivery-dropdown-select .ant-select {
  color: #00425f;
}

.delivery-dropdown-select .ant-select-arrow {
  color: #00425f;
}

.delivery-dropdown-select .ant-select-focused > .ant-select-selection,
.delivery-dropdown-select .ant-select-selection:focus {
  border-color: #00425f;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.delivery-dropdown-select .ant-select-selection:hover {
  border-color: #00425f;
}

.delivery-dropdown-select .ant-select-selection:active {
  border-color: #00425f;
}

label[for='errorTarget'] {
  margin: 4px 0 0 8px;
}

label[for='errorTarget'][data-shrink='true'] {
  color: #ee312a !important;
}

.graph-container .ant-spin-nested-loading {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.file-picker .MuiOutlinedInput-root-41.MuiOutlinedInput-focused-42 .MuiOutlinedInput-notchedOutline-48 {
  border-color: #00425f !important;
}

.MuiFormLabel-root-34.MuiFormLabel-focused-35 {
  color: #00425f !important;
}

.upload-btn {
  color: #00425f !important;
}

.red {
  color: #ee312a;
}

.green {
  color: #2DB004;
}
