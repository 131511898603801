.container {
    width: 100%;
    margin: 0 auto;
    padding: 0px 20px 20px;
    background-color: #fff;
}

.form-row {
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 15px; */
}

.form-group {
    width: 48%;
}

.form-group.full-width {
    width: 100%;
}

label {
    display: block;
    margin-bottom: 5px;
    color: #353F50;
    font-size: 12px;
    line-height: 16px;
    font-weight: 800;
    font-family: 'Averta', sans-serif;
}

input[type="text"],
input[type="email"],
input[type="password"],
select {
    width: 100%;
    padding: 8px;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    background-color: #F3F5F6;
}

input[type="text"].error,
input[type="email"].error,
input[type="password"].error,
select.error {
    border: 1px solid red;
}

.terms-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.terms-checkbox {
    margin-right: 10px;
}

.terms-label {
    font-weight: 400;
    color: #4E5A6C;
    margin-top: 5px;
}

.terms-label a {
    color: #0275D8;
    text-decoration: none;
    /* Remove underline */
}

.error-message {
    color: red;
    font-size: 0.875em;
    margin-top: 2px;
}

button {
    width: 80%;
    /* Set width to 80% */
    display: block;
    /* Ensure block-level display for margin: 0 auto; to work */
    margin: 0 auto;
    /* Center align */
    padding: 8px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    /* Center align text within the button */
}

button:disabled {
    background-color: #cccccc;
}

.loader {
    border: 4px solid #f3f3f3;
    /* Light grey */
    border-top: 4px solid #007bff;
    /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.signin-text {
    text-align: center;
    margin-top: 15px;
}

.signin-text a{
   color: #0275D8;
}