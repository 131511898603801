.filter-input {
  color: #212529 !important;
  display: inline-block !important;
  width: 46%;
  margin-right: 4px;
}

.filter-input input[type="text"] {
  color: #212529 !important;
}
