@import "trumps";
@import "reset";
@import "loadingpage";
@import "pending";
@import "notify";
@import "svg";
@import "error";

.isw-mainLayout1 {
    overflow: hidden scroll;
    padding-left: 105px;

    @media (min-width: 320px) and (max-width: 991px) {
        padding: 0;
    }
}

.isw-nav--header {
    min-height: 64px;
    padding: 0 1rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    @media (min-width: 320px) and (max-width: 991px) {
        svg {
            margin-left: 1rem;
        }
    }

    .isw-nav--pad .isw-logo-menu {
        left: -20px;
        position: absolute;
    }
}

.toggler-cont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid #ddd;
    color: #fff;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 61px;
}

.form_field__control_landing {
    background: transparent;
    margin: 0;
}

input.k_input {
    margin: 0;
    background: transparent;
    -webkit-text-fill-color: rgba(#000, 0.7);
    padding: 1rem 0.75rem;
    height: 54px;
    border: 1px solid rgba(#e8e8e8, 0.9);

    &:hover,
    &:focus {
        border-color: rgba(#00425f, 0.3);
    }

    &.green {
        border-color: rgba(#3bb75e, 0.52);
    }

    &.red {
        border-color: #ee312a;
    }

    &.isw-input-error {
        border-color: #ee312a;
    }
}

.csr {
    cursor: pointer;
}

.text-c {
    text-align: center;
}

.disabled {
    color: #e0e0e0 !important;
    cursor: not-allowed !important;
}

.bg-primary.false {
    background: #e0e0e0 !important;
    color: #fff;

    &:hover {
        background: #e0e0e0 !important;
        -webkit-box-shadow: none;
        box-shadow: none;
        -webkit-transform: none;
        transform: none;
        cursor: default;
    }
}

.isw-customer {
    position: inherit;

    nav {
        position: inherit;

        .breadcrumb {
            background: #fff;
            border: 1px solid #dee2e6;

            .breadcrumb-item {
                font-size: 1em;
                font-weight: 400;
                color: rgba(#000, 0.4);

                &.active {
                    color: #00425f;
                    font-weight: 500;
                }
            }
        }
    }

    .accordion {
        position: inherit;

        .card-header {
            padding: 0;

            .btn-link {
                padding: 1rem;
                width: 100%;
                text-align: left;
                text-decoration: none;
                color: #00425f;
                font-weight: 500;
            }
        }
    }

    @-webkit-keyframes rotate {
        0% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes rotate {
        0% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes wink {
        50% {
            opacity: 0;
        }
    }

    @keyframes wink {
        50% {
            opacity: 0;
        }
    }

    .spinner {
        width: 7em;
        margin: 3em auto;
        position: relative;

        &:before {
            content: "";
            display: block;
            width: 7em;
            height: 1em;
            margin: 3em auto;
            padding: 3em 0;
            border-radius: 50%;
            border: 2px solid #2f416f;
            border-top: 2px solid transparent;
            border-bottom: 2px solid transparent;
            -webkit-animation: rotate 1s ease infinite;
            animation: rotate 1s ease infinite;
        }

        &:after {
            content: "Loading";
            position: absolute;
            top: 0;
            color: #2f416f;
            font: .9em sans-serif;
            width: 9em;
            line-height: 8em;
            text-align: center;
            -webkit-animation: wink 1.4s ease infinite;
            animation: wink 1.4s ease infinite;
        }
    }
}

.bg-cyan {
    background-color: #00B8DE !important;

    &:hover,
    &:focus {
        background-color: #008eab !important;
    }
}

.isw-infoBox-success {
    background: rgba(#2DB004, 0.1);
    border: 1px solid #2DB004;
    padding: 1rem;
}

.isw-infoBox-pending {
    background: rgba(#fd7e14, 0.1);
    border: 1px solid #fd7e14;
    padding: 1rem;
}

.isw-infoBox-failed {
    background: rgba(#ee312a, 0.1);
    border: 1px solid #ee312a;
    padding: 1rem;
}

svg#Capa_1 {
    height: 14px;
    width: 14px;
}

.isw-permission-name {
    .loading-modal-container {
        height: 100%;
    }
}

.isw-card-header .p-3 .shadow-sm {
    z-index: 100;
}

.k_input.search_field {
    border-color: #e8e8e8;
    margin: 0 0 0.5rem;
}

#location-item-span {
    padding: 0.5rem 1rem;
    margin: 4px 0;
    border: 1px solid rgba(#999, 0.1);
    text-transform: capitalize;
    background: #e8e8e8;
}

.dropdown.dropdown-toggle.kam {
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    &:hover {
        z-index: 100000000;

        .dropdown-menu.kam {
            z-index: 100000000000;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
        }
    }
}

#selected-loc {
    text-transform: capitalize;
}

.ovh-img {
    img {
        width: 96px;
    }

    #img-cont {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        widows: inherit;
        height: inherit;
        background: #fff;

        img {
            width: 96px;
        }
    }
}

.nothing-dashboard {
    color: rgba(0, 0, 0, 0.5);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0.5rem 0 0;
}

.isw-card-admin-list.kam {
    border-bottom: 1px solid #e8e8e8;
    padding: 0 0 1rem;

    p {
        padding: 1rem 0 0;
    }
}

.user-perms {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 8px 0 0;
}

.isw-user-permission-action {
    position: relative;
}

.isw-logo-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.editInfo {
    padding: 0;
}

.isw-sideBar-a {
    padding: 0 0 0 30px;
}

.nav-pills>.nav-item {
    cursor: pointer;
}

// leave them at the end
.ant-modal-title {
    color: #00425f;
}

.ant-modal-close-x {
    color: #ee312a;
}

.ant-input:hover,
.ant-input:focus,
.ant-input:active {
    border-color: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ant-modal-wrap,
.ant-notification,
.ant-message,
.ant-modal-mask {
    z-index: 10000;
}



.ant-table-thead>tr>th .anticon-filter {
    position: absolute;
}

.ant-table-tbody {
    border: 1px solid #e8e8ee;
}

.ant-spin-nested-loading {
    border: none;
    height: 100vh;
    overflow: hidden scroll;
    background: transparent;

    .ant-list-item {
        width: calc(100% - 1.25rem);
    }
}

.ant-upload.ant-upload-select.ant-upload-select-picture-card:hover {
    border-color: #00425f;
}

.ant-upload-list-picture-card .ant-upload-list-item-error {
    border-color: #ee312a;
}

.isw-btn>.material-icons {
    margin: 0 0.5rem 0 0;
}

.ant-form-explain>span {
    display: block;
}

.anticon-right>svg,
.anticon-left>svg {
    height: 1rem;
    width: 1rem;
}

.ant-message-custom-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.isw-sideBar {
    overflow: hidden;
    width: 240px;
    top: 0;
    border-right: 1px solid #e8e8e8;
}

.ant-radio-group {
    padding: 0.05rem 1rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.ant-drawer-header {
    position: fixed;
    z-index: 2;
    min-height: 65px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    padding: 0.4rem 0;
}

.ant-drawer-body {
    margin: 3rem 0;

    .col-lg-11 {
        border: none;
        padding: 0;
    }

    .isw-card {
        border: 0;
    }
}

.drawer-btn-cont {
    position: absolute;
    bottom: 0;
    max-height: 3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    border-top: 1px solid #e8e8e8;
    padding: 10px 16px;
    text-align: right;
    left: 0;
    background: #fff;
    border-radius: 0 0 4px 4px;

    span {
        font-size: 12px;
        letter-spacing: 0;
        color: #000;
    }

    .text-blue {
        margin: 0 1rem;

        span {
            color: #00425f;
        }

        &:hover {
            background: rgba(#00425f, 0.1);
        }
    }
}

// leave as last
input.k_input {
    background: #e8e8e8;
}

.ant-pagination {
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;

    .ant-pagination-item {
        margin: 0;
        border-top: none;
        border-bottom: none;
        border-radius: 0;
        color: #00425f;

        &:hover {
            border-color: #dee2e6;
        }
    }

    .ant-pagination-item-active {
        background: #00425f;
        border-color: #00425f;
        -webkit-transform: scale(1.1);
        transform: scale(1.1);

        a {
            color: #fff;

            &:hover {
                color: #fff;
            }
        }
    }

    .ant-pagination-prev,
    .ant-pagination-next {
        padding: 0 1rem;
        color: #00425f;
        background: #fff;
        margin: 0;

        &.ant-pagination-disabled {
            color: #6c757d;
        }
    }
}

.dropdown-menu-kam,
.dropdown-menu-kam2 {
    background: #fff;
}

.dropdown-item {
    color: #00425f;

    &:hover {
        background: #f8f9fa;
        cursor: pointer;
    }
}

.openModal {
    display: block;
    background: rgba(#000, 0.2);
    width: 100%;

    .isw-sideBarNav {
        width: 105px;
    }
}

label[for='cssTarget'] {
    margin: 4px 0 0 8px;

    &[data-shrink='true'] {
        color: #00425f;
    }
}

.form-field__input[id='cssTarget']:hover {
    border: none !important;
}

.daterangepicker .ranges li.active {
    background-color: #00425f !important;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background-color: #00425f !important;
}

.isw-valign--middle {
    word-break: break-all;
}

.progress-bar {
    background: #00425f !important;
}


.delivery-card {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 12px;

    .complete-btn {
        background: #02567C;
        color: #fff;
        padding: 12px;
        border-radius: 4px;
        max-width: 120px;
        width: 100%;
        text-align: center;
    }
}

.activeCard {
    background: #02567C !important;

    &,
    span {
        color: white;
    }

    .complete-btn {
        background: #fff;
        color: #02567C;
    }
}

.react-bootstrap-daterangepicker-container {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 0 0 auto;
}

.isw-table--body>span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.contract-detail {
    .ant-spin-nested-loading {
        height: unset;
    }
}

.ant-drawer-title {
    width: 100%;
    min-width: 240px;
}

.isw-permission {
    max-height: 550px;

    .row {
        max-height: 520px;

        .col-5,
        .col-7,
        .isw-permission-list {
            max-height: inherit;
            height: unset;
        }

        .isw-permission-list {
            height: 390px;
            min-height: unset !important;
        }
    }
}

.profile-page {
    .isw-table--body {
        padding: 6px 12px;
    }

    .ant-spin-nested-loading {
        height: 100%;
    }
}


.otp-modal {
    .ant-btn-primary {
        background: #00425f;
        border-color: #00425f;
    }

    input::-webkit-input-placeholder {
        color: #00425f
    }

    input:-ms-input-placeholder {
        color: #00425f
    }

    input::-ms-input-placeholder {
        color: #00425f
    }

    input::placeholder {
        color: #00425f
    }
}



.delivery-dropdown-select {
    .ant-select {
        color: #00425f;
    }

    .ant-select-arrow {
        color: #00425f
    }

    .ant-select-focused>.ant-select-selection,
    .ant-select-selection:focus {
        border-color: #00425f;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .ant-select-selection:hover {
        border-color: #00425f
    }

    .ant-select-selection:active {
        border-color: #00425f
    }

}

label[for='errorTarget'] {
    margin: 4px 0 0 8px;

    &[data-shrink='true'] {
        color: #ee312a !important;

    }
}


.graph-container {
    .ant-spin-nested-loading {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content
    }
}



.file-picker {
    .MuiOutlinedInput-root-41.MuiOutlinedInput-focused-42 .MuiOutlinedInput-notchedOutline-48 {
        border-color: #00425f !important
    }
}



.MuiFormLabel-root-34.MuiFormLabel-focused-35 {
    color: #00425f !important
}

.upload-btn {
    color: #00425f !important
}


.red {
    color: #ee312a
}


.green {
    color: #2DB004
}